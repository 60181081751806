// Copyright 2019 Istio Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

const click = "click";
const mouseenter = "mouseenter";
const mouseleave = "mouseleave";
const active = "active";
const keyup = "keyup";
const keydown = "keydown";
const button = "button";
const ariaLabel = "aria-label";
const ariaExpanded = "aria-expanded";
const ariaSelected = "aria-selected";
const ariaControls = "aria-controls";
const tabIndex = "tabindex";
